import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { jwtDecode } from "jwt-decode";
import { AFTER_SIGN_OUT_PATH } from "../routes/landing";
import { cleanUserArtifacts } from "./user";
import { retrieveAuthToken } from "./authService";
import { cognitoPoolData } from "../constants/cognitoPoolData";

const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

/**
 * Signs in a user to Cognito using the provided tokens. Follows this up by retrieving the authToken.
 * Used after SAML authentication to get the user signed in on the browser. (caled from user model)
 *
 * @param {Object} tokens - The tokens required for Cognito sign-in.
 * @param {string} tokens.idToken - The ID token for the user.
 * @param {string} tokens.accessToken - The access token for the user.
 * @param {string} tokens.refreshToken - The refresh token for the user.
 * @returns {Promise<void>} A promise that resolves when the sign-in process is complete.
 */
export const doCognitoSignIn = async tokens => {
  const email = getEmailFromJWT(tokens.idToken);

  const cognitoUserSession = new CognitoUserSession({
    IdToken: new CognitoIdToken({ IdToken: tokens.idToken }),
    AccessToken: new CognitoAccessToken({ AccessToken: tokens.accessToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: tokens.refreshToken }),
  });

  getCognitoUser({ email }).setSignInUserSession(cognitoUserSession);

  await retrieveAuthToken();
};

/**
 *
 * @param {Object} credentials - The credentials for the user.
 * @param {string} credentials.email - User's email
 * @param {string} credentials.password - User's password
 * @returns
 */
export const cognitoPasswordAuth = async ({ email, password }) => {
  const userData = {
    Username: email.toLowerCase(),
    Password: password,
  };

  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(userData);
  const cognitoUser = getCognitoUser({ email });
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async () => {
        await retrieveAuthToken();
        resolve();
      },
      onFailure: err => reject(err.message),
    });
  });
};

/**
 * Signs up a user in Cognito for username/password authentication.
 * @param {Object} options - Options for the cognitoSignup function.
 * @param {string} options.password - The password for the new user.
 * @param {string} options.email - The email for the new user.
 * @param {Object} options.attributes - Additional attributes for the new user.
 * @param {Object} options.additionalData - Additional data to pass along with the registration event (for lambda triggers).
 * @param {Function} options.cb - A callback function to call with any errors.
 * @param {Function} options.success - A callback function to call with a success message.
 */
export const cognitoSignup = ({
  password,
  email,
  attributes = {},
  metadata = null,
  cb,
  success,
}) => {
  try {
    validateUserFields({ email, password });
    const attributeList = buildCognitoUserAttributeList({
      ...attributes,
      email: email.toLowerCase(),
    });
    const userPool = getCognitoUserPool();

    userPool.signUp(
      email.toLowerCase(),
      password,
      attributeList,
      null,
      (err, result) => {
        if (err) {
          cb(err.message);
          return;
        }

        success("We have sent you an email to verify your account", result.user);
      },
      metadata
    );
  } catch (err) {
    cb(err.message);
  }
};

/**
 * Called from authService helper to get the current Cognito JWT token.
 * If the token is expired, it will attempt to refresh it.
 * @param {Object} options - Options for the getCognitoJwt function.
 * @param {Boolean} options.forceRefresh - Force a refresh regardless of token expiration.
 * @param {string} options.noRedirect - Don't redirect to the sign-out page if the user is not authorized.
 * @returns {Promise<string>} A promise that resolves with the JWT token for the current user.
 */
export const getCognitoJwt = async ({ forceRefresh = false, noRedirect = false }) => {
  let token = null;
  const user = getCognitoCurrentUser();
  if (!user) {
    // No current user, so no Jwt to return
    return null;
  }
  try {
    const session = await getUserSession(user);
    token = session.getIdToken();

    // If forceRefresh OR the current token is expired, try to get a new one
    const now = new Date();
    if (forceRefresh || now.getTime() > token.getExpiration() * 1000) {
      token = await refreshCognitoUserSession(user, session.getRefreshToken());
    }
  } catch (e) {
    if (e.name === "NotAuthorizedException") {
      console.log("Caught NotAuthorizedException while getting user session - logging out");
      if (!noRedirect) {
        cognitoSignOut();
        cleanUserArtifacts();
        window.location.href = AFTER_SIGN_OUT_PATH;
      }
    }
    return null;
  }

  return token.getJwtToken();
};

/**
 * Get current Cognito user
 * @returns {CognitoUser} The current Cognito user.
 */
export const getCognitoCurrentUser = () => {
  return getCognitoUserPool().getCurrentUser();
};

/**
 * Removes locally stored Cognito tokens and keys
 */
export const cognitoSignOut = () => {
  const user = getCognitoCurrentUser();
  if (user) {
    user.signOut();
  }
};

/**
 * Performs a Cognito GlobalSignOut for the current user
 */
export const cognitoGlobalSignOut = async () => {
  let user;
  try {
    user = getCognitoCurrentUser();
    if (!user) {
      return null;
    }

    await getUserSession(user);
    return new Promise(resolve => {
      user.globalSignOut({
        onSuccess: msg => {
          resolve(msg);
        },
        onFailure: err => {
          console.error(
            `error performing GlobalSignOut. falling back to local sign out: ${err.message}`
          );
          user.signOut();
        },
      });
    });
  } catch (err) {
    console.error(`error getting user or session. falling back to local sign out: ${err.message}`);
    if (user) {
      user.signOut();
    }
  }
};

/**
 * Get the current Cognito user's session.
 * @param {CognitoUser} user - The Cognito user to get the session for.
 * @returns
 */
const getUserSession = user => {
  return new Promise((resolve, reject) => {
    user.getSession((err, session) => {
      if (err) return reject(err);

      resolve(session);
    });
  });
};

const refreshCognitoUserSession = async (cognitoUser, refresh_token) => {
  return new Promise(resolve => {
    cognitoUser.refreshSession(refresh_token, async (err, session) => {
      if (err) {
        console.log(err);
        cognitoSignOut();
        cleanUserArtifacts();
        window.location.href = AFTER_SIGN_OUT_PATH;
      } else {
        let idToken = session.getIdToken();
        resolve(idToken);
      }
    });
  });
};

export const getCognitoUser = ({ email }) => {
  const userPool = getCognitoUserPool();

  const userData = {
    Username: decodeURIComponent(email.toLowerCase()),
    Pool: userPool,
  };

  return new CognitoUser(userData);
};

/**
 * Get a Cognito user pool object.
 * @returns {CognitoUserPool} The Cognito user pool.
 */
const getCognitoUserPool = () => {
  return new CognitoUserPool(cognitoPoolData());
};

const getEmailFromJWT = jwt => {
  const claims = jwtDecode(jwt);

  if ("email" in claims) {
    return claims.email;
  } else if (
    "cognito:username" in claims &&
    claims["cognito:username"].includes("@") &&
    claims["cognito:username"].includes(".")
  ) {
    const username = claims["cognito:username"];

    const i = username.indexOf("_");
    const emailParts = [username.slice(0, i), username.slice(i + 1)];

    if (emailParts.length > 1) {
      return emailParts[1];
    }
    return emailParts[0];
  }

  return null;
};

const buildCognitoUserAttributeList = obj => {
  const attribute_maps = {
    givenName: "name",
    familyName: "family_name",
    company: "custom:company",
    jobRole: "custom:job_role",
    title: "custom:title",
    email: "email",
  };

  return Object.keys(obj).map(key => {
    return new CognitoUserAttribute({
      Name: attribute_maps[key],
      Value: obj[key],
    });
  });
};

const validateUserFields = fields => {
  const errors = {};
  let invalid = false;
  for (const field in fields) {
    if (!fields[field]) {
      invalid = true;
      errors[field] = "Can't be blank";
    }
  }
  if (invalid) {
    throw { response: { data: { errors } } };
  }
};

export const updateCognitoUserEmail = async newEmail => {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoCurrentUser();
    getUserSession(cognitoUser);

    if (!cognitoUser) {
      console.error("updateCognitoUserEmail: No Cognito user found.");
      return reject("No Cognito user found.");
    }

    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: newEmail.toLowerCase(),
      }),
    ];

    const metadata = { emailUpdateType: "sj-user-email-change" };

    cognitoUser.updateAttributes(
      attributeList,
      (err, result) => {
        if (err) {
          console.error("updateCognitoUserEmail: Failed to update email in Cognito:", err);
          return reject(err.message || "Failed to update email in Cognito.");
        }

        console.log("updateCognitoUserEmail: Email updated successfully in Cognito.", result);
        resolve("Email updated successfully. Check your inbox for verification.");
      },
      metadata
    );
  });
};

export const verifyEmailWithCode = async code => {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoCurrentUser();
    getUserSession(cognitoUser);

    if (!cognitoUser) {
      console.error("verifyEmailWithCode: No Cognito user found.");
      return reject("No Cognito user found.");
    }

    cognitoUser.verifyAttribute("email", code, {
      onSuccess: () => {
        console.log("Email verfied sucessfully");
        resolve("Email verified successfully.");
      },
      onFailure: err => {
        console.error("Failed to verify email", err);
        reject(err.message || "Failed to verify email.");
      },
    });
  });
};
