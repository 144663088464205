import { useFlags } from "launchdarkly-react-client-sdk";

export const useSjExportAssignmentsFlag = () => {
  const { sjExportAssignments } = useFlags();

  return sjExportAssignments;
};

export const useEnableAssignLicense = () => {
  const { assignLicense } = useFlags();

  return assignLicense;
};

export const useEnableArchiveThroughSvcDirectory = () => {
  const { enableArchiveThroughSvcDirectory } = useFlags();

  return enableArchiveThroughSvcDirectory;
};

export const useAdminRedirectOnLogin = () => {
  const { takeAdminToAdminPageOnLogin } = useFlags();

  return takeAdminToAdminPageOnLogin;
};

export const useActiveOnlyUsersForLicense = () => {
  const { enableActiveOnlyUserCountForLicense } = useFlags();

  return enableActiveOnlyUserCountForLicense;
};

export const useUpdateMyPathsInAdminPage = () => {
  const { updateMyPathsInAdminPage } = useFlags();

  return updateMyPathsInAdminPage;
};

export const useHideDashboardReports = () => {
  const { hideDashboardReports } = useFlags();

  return hideDashboardReports;
};

export const useEditTournamentsAudiencesEnabled = () => {
  const { editTournamentsAudiencesEnabled } = useFlags();

  return editTournamentsAudiencesEnabled;
};

export const useDeductPointsInTournamentMode = () => {
  const { deductPointsInTournamentMode } = useFlags();

  return deductPointsInTournamentMode;
};

export const useNotStartedStatusAssignmentReport = () => {
  const { notStartedStatusAssignmentReport } = useFlags();

  return notStartedStatusAssignmentReport;
};

export const useSjBreakfixBrowserButtons = () => {
  const { sjBreakfixBrowserButtons } = useFlags();
  return sjBreakfixBrowserButtons;
};

export const useTimeTracker = () => {
  const { timetracker } = useFlags();

  return timetracker;
};

export const useHideMicroPaths = () => {
  const { hideMicroPaths } = useFlags();

  return hideMicroPaths;
};

export const useSjKnowledgeAssessments = () => {
  const { sjKnowledgeAssessments } = useFlags();

  return sjKnowledgeAssessments;
};

export const useNoImmediateRefresh = () => {
  const { noImmediateRefresh } = useFlags();

  return noImmediateRefresh;
};

export const useTempTimeoutFix = () => {
  const { tempTimeoutFix } = useFlags();

  return tempTimeoutFix;
};

export const useSvcIdpRedirect = () => {
  const { useServiceIdpForRedirect } = useFlags();

  return useServiceIdpForRedirect;
};

export const useNewUsersPage = () => {
  const { newUsersPage } = useFlags();

  return newUsersPage;
};

export const useComplianceReport = () => {
  const { useComplianceReport } = useFlags();

  return useComplianceReport;
};

export const useSafeAttachmentLoading = () => {
  const { safeAttachmentLoading } = useFlags();

  return safeAttachmentLoading;
};

export const useAllowAssessmentDemos = () => {
  const { allowAssessmentDemos } = useFlags();

  return allowAssessmentDemos;
};

export const useStrictAssignmentModal = () => {
  const { strictAssignmentModal } = useFlags();

  return strictAssignmentModal;
};

export const useStrictKnowledgeAssessment = () => {
  const { strictKnowledgeAssessment } = useFlags();

  return strictKnowledgeAssessment;
};

export const useKaProgressBars = () => {
  const { kaProgressBars } = useFlags();

  return kaProgressBars;
};

export const useDisplayDeveloperProfile = () => {
  const { displayLearnerProfile, forceDeveloperProfileModal } = useFlags();

  return {
    isDeveloperProfileEnabled: displayLearnerProfile,
    isForceDeveloperProfileModalEnabled: forceDeveloperProfileModal,
  };
};

export const useCreateTenantIdp = () => {
  const { createTenantIdpEndpoint } = useFlags();

  return createTenantIdpEndpoint;
};

export const useAdminV2Routes = () => {
  const { itemsEnabledForNewAdminUi } = useFlags();

  return itemsEnabledForNewAdminUi || [];
};

export const useHubspotChat = () => {
  const { hubspotChat } = useFlags();

  return hubspotChat;
};

export const useIncludeCustomCertDescription = () => {
  const { includeCustomCertDescription } = useFlags();

  return includeCustomCertDescription;
};

export const useIsScormExportEnabled = () => {
  const { enableScormExport } = useFlags();

  return enableScormExport;
};

export const useDynamicAssessmentDates = () => {
  const { dynamicAssessmentDates } = useFlags();

  return dynamicAssessmentDates;
};
